import React, { useState, useRef } from "react";
import style from "./save-as-template.module.css";
import { useOnClickOutside } from "usehooks-ts";
import { useHover } from "frontend/ui-components/floaters/use-hover";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";

export default function SaveAsTemplate({
  onSaveTemplateToLibrary,
  onSaveTemplateAsLink,
  createTemplateEnable,
}: {
  onSaveTemplateToLibrary: () => void;
  onSaveTemplateAsLink: () => void;
  createTemplateEnable: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const saveTemplateRef = useRef<HTMLDivElement>(null);
  const saveTemplateHover = useHover(saveTemplateRef);

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const tooltip = (
    <div style={{ textAlign: "left", fontSize: 12, fontWeight: 300 }}>
      <p>Save as template</p>
    </div>
  );

  const templatesIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11097_231)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H20V20H0V0ZM1.39535 1.39535V5.34884H18.6047V1.39535H1.39535ZM1.39535 18.6047V6.74419H5.34884L5.34884 18.6047H1.39535ZM6.74419 18.6047H18.6047V6.74419H6.74419L6.74419 18.6047Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11097_231">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const saveToLibIcon = (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42727 5.38011L6.49805 8.40697L6.49805 0.611328L5.50189 0.611328L5.50189 8.40666L2.57296 5.38011L1.85712 6.07286L5.64219 9.98411C5.73603 10.0811 5.86518 10.1358 6.00011 10.1358C6.13504 10.1358 6.2642 10.0811 6.35804 9.98411L10.1431 6.07286L9.42727 5.38011ZM0.996161 9.31124H0V13.1438H12V9.64469H11.0038V12.1477H0.996161V9.31124Z"
        fill="#113357"
      />
    </svg>
  );

  const saveToLinkIcon = (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.27783 7.63843C5.49612 7.85844 5.75667 8.03323 6.04422 8.15255C6.33177 8.27187 6.64053 8.33333 6.95244 8.33333C7.26434 8.33333 7.57311 8.27187 7.86066 8.15255C8.14821 8.03323 8.40875 7.85844 8.62704 7.63843L11.3064 4.99246C11.7505 4.55386 12.0001 3.959 12.0001 3.33873C12.0001 2.71846 11.7505 2.12359 11.3064 1.685C10.8623 1.2464 10.2599 1 9.63181 1C9.00371 1 8.40133 1.2464 7.9572 1.685L7.62228 2.01574"
        stroke="#113357"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M7.72222 5.3619C7.50393 5.14189 7.24339 4.9671 6.95584 4.84778C6.66829 4.72845 6.35952 4.66699 6.04762 4.66699C5.73571 4.66699 5.42694 4.72845 5.1394 4.84778C4.85185 4.9671 4.5913 5.14189 4.37301 5.3619L1.69364 8.00787C1.24951 8.44646 1 9.04133 1 9.6616C1 10.2819 1.24951 10.8767 1.69364 11.3153C2.13778 11.7539 2.74015 12.0003 3.36825 12.0003C3.99635 12.0003 4.59872 11.7539 5.04285 11.3153L5.37778 10.9846"
        stroke="#113357"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );

  const infoIcon = (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="6.38867" y1="3.44531" x2="6.38867" y2="4.66753" stroke="#0B2642" />
      <line x1="6.38867" y1="3.44531" x2="6.38867" y2="4.66753" stroke="#0B2642" />
      <line x1="6.38867" y1="5.88867" x2="6.38867" y2="9.55534" stroke="#0B2642" />
      <line x1="6.38867" y1="5.88867" x2="6.38867" y2="9.55534" stroke="#0B2642" />
      <path
        d="M1 6.5C1 7.22227 1.14226 7.93747 1.41866 8.60476C1.69506 9.27205 2.10019 9.87836 2.61091 10.3891C3.12163 10.8998 3.72795 11.3049 4.39524 11.5813C5.06253 11.8577 5.77773 12 6.5 12C7.22227 12 7.93747 11.8577 8.60476 11.5813C9.27205 11.3049 9.87836 10.8998 10.3891 10.3891C10.8998 9.87836 11.3049 9.27205 11.5813 8.60476C11.8577 7.93747 12 7.22227 12 6.5C12 5.04131 11.4205 3.64236 10.3891 2.61091C9.35764 1.57946 7.95869 1 6.5 1C5.04131 1 3.64236 1.57946 2.61091 2.61091C1.57946 3.64236 1 5.04131 1 6.5Z"
        stroke="#0B2642"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const options = [
    {
      icon: saveToLibIcon,
      text: "Save in library",
      info: "Create a template in your library",
      type: "library",
      onClick: () => {
        onSaveTemplateToLibrary();
        setIsOpen(false);
      },
      width: 220,
    },
    {
      icon: saveToLinkIcon,
      text: "Save as a link",
      info: "Create a template link to your canvas. Anyone with the link can use this canvas as a template.",
      type: "link",
      onClick: () => {
        onSaveTemplateAsLink();
        setIsOpen(false);
      },
      width: 254,
    },
  ];

  const optionRenderer = (option: any) => {
    return (
      <div className={style.option} onClick={option.onClick} key={option.type}>
        <div className={style.type}>
          {!createTemplateEnable && <img src={"/images/golden-crown.svg"} height={10} />}
          {option.icon}
          {option.text}
        </div>
        <div
          className={style.infoIconWrapper}
          onMouseEnter={() => setActiveTooltip(option.type)}
          onMouseLeave={() => setActiveTooltip(null)}
        >
          {infoIcon}
          {activeTooltip === option.type && (
            <div className={style.tooltip} style={{ width: option.width }}>
              <div className={style.tooltipArrow}></div>
              {option.info}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={style.container} ref={dropdownRef}>
      <div className={style.buttonWrapper}>
        <div className={style.button} onClick={() => setIsOpen(!isOpen)} ref={saveTemplateRef}>
          {templatesIcon}
          <img
            src={"/images/chevron-blue.svg"}
            style={{ transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)", transition: "transform 0.2s ease-in-out" }}
          />
        </div>
        {isOpen && <div className={style.optionsContainer}>{options.map((option) => optionRenderer(option))}</div>}
        {saveTemplateHover && !isOpen && (
          <Tooltip
            label={tooltip}
            relativeTo={saveTemplateRef}
            side={"bottom"}
            customStyle={{ borderRadius: 3, padding: 14, top: 46, marginLeft: -7 }}
          />
        )}
      </div>
    </div>
  );
}
