import React from "react";

export const MagnifyingGlassIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <g stroke="#848199" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#clip0_466_28896)">
        <path d="M5.667 10.333a4.667 4.667 0 100-9.333 4.667 4.667 0 000 9.333zM13.001 13l-4-4"></path>
      </g>
      <defs>
        <clipPath id="clip0_466_28896">
          <path fill="#fff" d="M0 0H14V14H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
