import { User } from "shared/datamodel/schemas/user";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { getSubscribedSeats, getUsersCount } from "frontend/api";
import { accountSubscribedCountAtom, userAccountCountAtom } from "state-atoms";
import useFeatureValue from "./use-features";
import consts, { MIN_PAID_SEATS } from "shared/consts";

export default function useSeatsCount(user: User | null) {
  const [userAccountCount, setUserAccountCount] = useAtom(userAccountCountAtom);
  const [accountSubscribedCount, setAccountSubscribedCount] = useAtom(accountSubscribedCountAtom);
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";

  useEffect(() => {
    if (userAccountCount === 0 && user) {
      loadUserAccountCount();
    }
  }, []);

  async function loadUserAccountCount() {
    try {
      const userAccountCount = await getUsersCount();
      setUserAccountCount(userAccountCount);
    } catch {
      console.error("Failed to load user account count");
    }
  }

  async function loadAccountSubscribedCount() {
    try {
      let count = userAccountCount;
      if (applyRestrictedLimitation) {
        count = await getSubscribedSeats(applyRestrictedLimitation);
      }
      setAccountSubscribedCount(count);
    } catch {
      console.error("Failed to load account subscribed count");
    }
  }

  async function calculateSeatsToBuy() {
    if (accountSubscribedCount === 0) {
      const subscribedCount = await getSubscribedSeats(false); //for now we want to always charge on all the account seats
      setAccountSubscribedCount(subscribedCount);
      return Math.max(MIN_PAID_SEATS, subscribedCount);
    } else {
      return Math.max(MIN_PAID_SEATS, accountSubscribedCount);
    }
  }
  return {
    userAccountCount,
    accountSubscribedCount,
    loadUserAccountCount,
    loadAccountSubscribedCount,
    calculateSeatsToBuy,
  };
}