import { BillingPeriod } from "frontend/billing/billing-period-picker";
import { stringToPlan } from "frontend/billingUtils";
import tracking from "frontend/tracking";
import consts, { Plan } from "shared/consts";

export function getPriceByPlanMapping(plansData: {name:string, price:number; trialDays:number, interval: BillingPeriod|null}[]) :  Record<Plan, Record<BillingPeriod, number>> {
  //const selectedPlanData = plansData?.find((i) => i.name.toLowerCase() === 'team'); //We assume all plans have the same trial days here, so we just take randomally from the teams plan. We should change it if this assumption changes
  //const trialDays = selectedPlanData!.trialDays;
  if (!plansData) {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "no-plansData-value");
  }
  const priceByPlan = plansData?.reduce((p:any,c) => {
    if(c.interval) {
      let intervalToPriceMap:any = {};
      const plan = stringToPlan(c.name.toLowerCase())!;
      intervalToPriceMap[c.interval] = c.price;
      if(p[plan]) {
        p[plan] = {
          ...p[plan],
          ...intervalToPriceMap
        }
      } else {
      p[plan] = intervalToPriceMap;
      }
    }
    return p;
  }, {})
  return priceByPlan;
}
