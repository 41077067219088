import Checkout from "frontend/billing/checkout";
import tracking from "frontend/tracking";
import StyledButton, { ButtonSize } from "frontend/ui-components/styled-button";
import React, { useEffect, useState } from "react";
import consts from "shared/consts";
import { User } from "shared/datamodel/schemas/user";
import { isTrialExpired, numberOfTrialDaysLeft, dateDiffInDays } from "shared/util/billing";
import style from "./trial-days-left-notification.module.css";
import EndOfTrialModal from "frontend/billing/end-of-trial-modal";

export default function TrialDaysLeftNotification({ user, renderedIn }: { user: User; renderedIn?: string }) {
  const daysLeft = numberOfTrialDaysLeft(user.planInfo!);
  const trialExpired = isTrialExpired(user.planInfo!);
  const trialDays =
    user.planInfo?.trial_start_date && user.planInfo?.trial_end_date
      ? dateDiffInDays(new Date(user.planInfo.trial_start_date), new Date(user.planInfo.trial_end_date))
      : consts.TRIAL_DAYS_DEFAULT_PERIOD;

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "trial-notification-shown", "days left", daysLeft);
  }, []);

  const [showUpgradeModal, setShowUpgradeModal] = useState(trialExpired);

  function upgradeClicked() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "trial-toolbar-upgrade-clicked", "days left", daysLeft);
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.BILLING,
      "trial-toolbar-upgrade-clicked-with-renderer-context",
      renderedIn
    );
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
    setShowUpgradeModal(true);
  }

  function renderTrialNotification() {
    return (
      <div className={style.toolbar}>
        <img src="/images/clock.svg" />
        <span className={style.description}>
          {daysLeft && !trialExpired ? (
            <React.Fragment>
              {`You have `}
              <b>{`${daysLeft} days`}</b> {` left on your Pro plan trial.`}
            </React.Fragment>
          ) : (
            <React.Fragment>{`Your trial has expired`}</React.Fragment>
          )}
        </span>
      </div>
    );
  }

  return (
    <React.Fragment>
      {showUpgradeModal && <EndOfTrialModal onDismiss={() => setShowUpgradeModal(false)} trialDays={trialDays} />}
      {renderTrialNotification()}
    </React.Fragment>
  );
}
